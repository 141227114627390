.input-btn-bridge {
    width: 400px;
    height: 60px;
    background-color: #ff7a92;
    color: black;
    padding: 10px 4px;
    border: none;
    cursor: pointer;
    margin-bottom: 4px;
    font-size: large;
    font-weight: bold;
    font-family: "PressStart2P";
}
.input-btn-bridge:hover {
    background-color: #ff94a7;
}

.input-bridge-field {
    width: 400px;
    height: 60px;
    background-color: #191919;
    color: #c6e992;
    text-align: center;
    border: none;
    cursor: pointer;
    font-size: 24px;
    font-family: "PressStart2P";
    margin-top: 20px;
}

.size-arrow {
    width: 88px;
    height: 65px;
}

.flex-item {
    justify-content: space-between;
  }

.mx-4 {
    margin-left: 4px;
    margin-right: 4px;
}