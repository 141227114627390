.form-container {
  background: #1e0102;
  flex-wrap: wrap;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 10%;
}
.form-box {
    min-width: 100px;
    max-width: 500px;
    min-height: 600px;
    background-color: #f2f2f2;
    padding: 20px;
    margin: auto;
}

.form-box-2 {
  min-width: 100px;
  max-width: 500px;
  min-height: 600px;
  background-color: #1a202d;
  color: white;
  padding: 20px;
  margin: auto;
}

.input-textfield {
    width: 90%;
    background-color: #f7f7f7;
    color: black;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.input-btn {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
  }

  .input-btn:hover {
    background-color: #45a049;
  }

  .format-form {
    flex-wrap: wrap;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    margin-bottom: 2%;
    padding-bottom: 10px;
  }

