a:link {
  text-decoration: none;
  color: #b4e492;
}
a:visited {
  text-decoration: none;
  color: #b4e492;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
  background-color: #000000;
  color: white;
  text-align: center;
}

.green-text {
  color: #b4e492;
}

.dark-bg {
  background-color: #1e0102;
  padding: 20px;
}

.orange-bg {
  background-color: #e59e4e;
}

.center {
  text-align: center;
}

.container {
  margin: 1rem;
  text-align: center;
  margin-bottom: 100px;
}
.c-left-home {
  text-align: left;
  max-width: 430px;
}
/* .nav {
  position: fixed;
  top: 0;
  background-color: #021824;
  width: 100%;
  height: 120px;
  color: #c6e992;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 1rem;
  padding: 0px .5rem;
} */

.small-text {
  font-size: 8px;
}

.green-border {
  border-color: #c6e992;
  border-style: solid;
  border-width: 2px;
  background-color: #072232;
}

.ml-4 {
  margin-left: 4px;
  padding: 5px;
}

.site-title {
  font-size: 2rem;
}

.ref-style {
  background-color: #021824;
  color: #1b1b1b;
  padding: 12px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 5px 5px 0px 0px;
}

.ref-style:hover, .ref-style:active {
  background-color: #dcffa7;
}

.App {
  background-color: #4c0409;
  background: #4c0409;
  display: block;
  min-height: 100vh;
  min-width: 200px;
  height: fit-content;
  overflow: hidden;
}

.claimed {
  background-color: rgba(255, 255, 255, 0.536);
  color: #0f0f0f;
  padding: 12px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  position: relative;
  border-radius: 25px 25px 0 0;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.status {
  align-items: center;
  text-align: center;
}

.user-info {
  background: #1e0102;
  flex-wrap: wrap;
  color: white;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  margin-bottom: 2%;
  padding-bottom: 20px;
}

.cover{
  height:100vh - 120px;
  overflow: scroll;
  position:absolute;
  width: 100vw; 
  align-items: center;
  justify-content: center;
  top:120px;
  left:0px;
  right:0px;
  bottom:0px;
}
.cover-image {
  width: 100vw;
  min-width: 400px;
}

.welcome-bar {
  padding-top: 120px;
  background: #4c0409;
  flex-wrap: wrap;
  color: white;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

}
.status-bar {
  background: #4c0409;
  flex-wrap: wrap;
  color: white;
}

.flex-container {
  display: flex;
  justify-items: center;
  align-items: center;
}
.width-50{
  width: 50%;
}

.highest {
  color: #4caf50;
}

.connected-account{
  background: #2f0204;
  width: 300px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px 5px 5px 5px;
}

.bridge-box{
  background: #021824;
  margin-top: 10px;
  padding-top: 10px;
  justify-content: center;

}

.balances {
  margin-top: 16px;
}

.connect-button {
  width: 100px;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 2%;
  margin-bottom: 2%;
  height: 40px;
  color: white;
  background-color: #e59e4e;
  border: 0;
  font-weight: 600;
  font-size: 1.1rem;
  position: relative;
}

.connect-button:hover {
  cursor: pointer;
  background-color: #fbad55;
}

.info-container {
  max-width: 400px;
  margin: auto;
  padding: 10px;
}

.connect-btn:hover {
  cursor: pointer;
  background-color: #fbad55;
}

.bid-button {
  background-color: #e59e4e;
  color: #fefeff;
  width: 80%;
  height: 60px;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 24px;
}

.bid-button:hover {
  cursor: pointer;
  background-color: #fbad55;
}

.purchase-button {
  background-color: #60e271;
  color: #fefeff;
  width: 80%;
  height: 60px;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 24px;
}

.purchase-button:hover {
  cursor: pointer;
  background-color: #6dff80;
}

.purchased-button {
  background-color: #737373;
  color: #fefeff;
  width: 80%;
  height: 60px;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 24px;
}

.text {
  text-align: center;
  font-size: 1rem;
}

.text-l {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  color: #c6e992;
  background-color: #021824;
  border-style: solid;
}

.text-winner {
  font-size: 1.1rem;
  font-weight: 600;
}


.text-account {
  color: #fbad55;
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
}

.flex {
  display: flex;
}

.box-small {
  max-width: 480px;
  padding: 4px;
  zoom: 1;
  align-items: center;
  margin: auto;
  line-height: 30px;
  padding-bottom: 40px;
}


.auction-container {
  background-color: #4c0409;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: center;
  max-width: 90%;
  margin: auto;
  padding-bottom: 60px;
}

.auction-card {
  display: inline-block;
  background-color: whitesmoke;
  width: 440px;
  min-height: 700px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0px 0px 0 0;
  border-style: solid;
  border-width: 8px;
}


.whitelist-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: center;
  max-width: 90%;
  margin: auto;
  padding-bottom: 25%;
}

.whitelist-card {
  display: inline-block;
  background-color: #021824;
  color: #e3e3e3;
  width: 440px;
  min-height: 700px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0px 0px 0 0;
  border-style: solid;
  border-width: 8px;
}


.whitelist-image {
  border-radius: 0px 0px 0 0;
  width: 100%;
}

.auction-image {
  border-radius: 0px 0px 0 0;
  width: 100%;
}

.card {
  margin: 2%;
}

.counter {
  margin-left: 25%;
  margin-right: 25%;
  font-size: 20px;
  text-align: center;
}

.content-item {
  border-bottom: 4px solid #e59e4e;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.orange-underline {
  border-bottom: 4px solid #e59e4e;
}

.address {
  font-size: 24px;
  font: bold;
  margin-bottom: 60px;
}
.address-card {
  font-size: 16px;
}

.inkz-image {
  width: 30px;
  height: 30px;
}

.inkz-image-m {
  width: 80px;
  height: 80px;
}

.greyed-button {
  background-color: #b0bcbc;
  color: #717171;
  width: 80%;
  height: 60px;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 24px;
}

.green-button {
  background-color: #60e271;
  color: #0f0f0f;
  width: 80%;
  height: 60px;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 24px;
}

.green-button:hover {
  cursor: pointer;
  background-color: #6dff80;
}

.between {
  justify-content: space-between;
  align-items: center;
}

.orange {
  color: #e59e4e;
}
